import { EntityAnnotation } from "..";
import {
  ConfigMap,
  getFlatEntityListFromConfigMap,
  GroupBlockEntityType,
} from "../../configMap";
import { Annotation } from "../../annotator/interfaces/annotation";
import { primaryColor } from "../../common/utilities/color";
import { EntityNormalization } from "../../annotator/interfaces/entity";
import { EntityAnnotationDto } from "../interfaces/annotation";
import { flattenGroupedOutput, groupAnnotations } from "./utils";

const createAnnotation = (
  entity: GroupBlockEntityType,
  entityAnnotation: EntityAnnotation,
  primaryColor: string
): Annotation => ({
  id: entityAnnotation.id!,
  page: entityAnnotation.page,
  entity: {
    id: entity.entityType.id,
    name: entity.entityType.id.toString(),
    color: entity.color || primaryColor,
    entityType: "NER",
    entityNormalizations: entity.entityType.entityNormalizations,
    multipleGroupBlocks: entity.multipleGroupBlocks,
  },
  values: entityAnnotation.value ? [entityAnnotation.value] : [],
  pageTokenIndices: entityAnnotation.pageTokenIndices,
  entityAnnotationNormalization: entityAnnotation.entityAnnotationNormalization,
  modelScore: entityAnnotation.modelScore,
  index: entityAnnotation.index,
  documentId: entityAnnotation.documentId,
  isByUser: entityAnnotation.isByUser,
  isOutput: entityAnnotation.isOutput || false,
  isLoading: false,
  multipleGroupBlocks: entity.multipleGroupBlocks,
});

const findByID = (id: string, list: Array<GroupBlockEntityType>) =>
  list.find((entity) => entity.entityType.id === id);

const mapAnnotations = (
  annotations: Array<EntityAnnotation>,
  entityList: Array<GroupBlockEntityType>
): Array<Annotation> =>
  annotations
    .map((annotation) => {
      const entity = findByID(annotation.entityTypeId!, entityList);
      if (!entity) {
        return null;
      }
      return createAnnotation(entity, annotation, primaryColor);
    })
    .filter((annotation): annotation is Annotation => annotation !== null);

export const mapAnnotationsToAnnotatorFormat = (
  entityAnnotations?: Array<EntityAnnotation>,
  configMap?: ConfigMap
): Array<Annotation> => {
  if (!configMap || !entityAnnotations || !entityAnnotations?.length) {
    return [];
  }

  const entityList = getFlatEntityListFromConfigMap(configMap);
  if (!entityList.length) {
    return [];
  }

  const annotations = entityAnnotations || [];
  const mappedAnnotations = mapAnnotations(annotations, entityList);
  const groupedAnnotations = groupAnnotations(mappedAnnotations);
  return flattenGroupedOutput(groupedAnnotations);
};

export const mapToEntityAnnotationDto = (
  id: string,
  value: string,
  index: number,
  entityNormalizations: Array<EntityNormalization>,
  entityTypeId: string | undefined,
  multipleGroupBlocks: boolean,
  categoryValue?: string,
  parentValue?: string
): EntityAnnotationDto => {
  return {
    id: id,
    value: value,
    index: index,
    entityNormalizations: entityNormalizations,
    entityTypeId: entityTypeId,
    categoryValue: categoryValue,
    multipleGroupBlocks,
    parentValue: parentValue || "",
  };
};

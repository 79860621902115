import useActiveDocument from "../../document/hooks/useActiveDocument";
import useAnnotations, { EntityAnnotation } from "../../annotation";
import { GroupBlockEntityType } from "../interfaces/entity";
import { useAppDispatch, useAppSelector } from "../../app";
import useAnnotationNormalization from "./useAnnotationNormalization";
import { useCallback, useMemo } from "react";
import { incrementOperations } from "../../analytics/store/analyticsSlice";
import { mapToEntityAnnotationDto } from "../../annotation/utils/annotationMappers";
import {
  changeActiveEntity,
  changeAnnotating,
  changeEditing,
} from "../../app/store/appSlice";
import { calcAverageScore } from "../utils/entityAnnotationUtils";

const useEntity = (
  groupBlockEntityType: GroupBlockEntityType,
  groupBlockIndex: number,
  disabled: boolean,
  multipleGroupBlocks: boolean
) => {
  const { entityType } = groupBlockEntityType;

  const { id } = entityType;

  const dispatch = useAppDispatch();

  const { isAnnotating, activeEntity, isEditing } = useAppSelector(
    (state) => state.appReducer
  );

  const { activeDocument } = useActiveDocument();
  const {
    annotationsForEntity,
    removeAnnotation: deleteAnnotation,
    isAnnotationLoading,
  } = useAnnotations({
    documentId: activeDocument?.id,
    entityId: id,
    groupBlockIndex,
  });

  const { normalizeEntityAnnotation } = useAnnotationNormalization();

  const removeAnnotation = useCallback(
    (annotationId: string) => {
      deleteAnnotation(annotationId, multipleGroupBlocks);
      dispatch(
        incrementOperations({
          numberOfAdditions: 0,
          numberOfUpdates: 0,
          numberOfDeletions: 1,
        })
      );

      const annotationsLeft = annotationsForEntity.filter(
        (annotation) => annotation.id !== annotationId
      );

      if (
        annotationsLeft.length >= 1 &&
        !annotationsLeft[0].entityAnnotationNormalization &&
        annotationsLeft[0].entity?.entityNormalizations?.length
      ) {
        const annotationToNormalize = annotationsLeft[0];

        const entityAnnotationDto = mapToEntityAnnotationDto(
          annotationToNormalize.id,
          annotationToNormalize.values.join(" "),
          annotationToNormalize.index || 1,
          annotationToNormalize.entity.entityNormalizations ?? [],
          annotationToNormalize.entity.id,
          annotationToNormalize.multipleGroupBlocks
        );
        normalizeEntityAnnotation(entityAnnotationDto);
      }
    },
    [
      deleteAnnotation,
      dispatch,
      annotationsForEntity,
      normalizeEntityAnnotation,
      multipleGroupBlocks,
    ]
  );

  const editingKey = useMemo(() => {
    return `${id}-${groupBlockIndex}`;
  }, [id, groupBlockIndex]);

  const handleEntityClick = useCallback(() => {
    if (disabled) {
      return;
    }

    if (activeEntity?.entityType.id === id) {
      dispatch(changeActiveEntity(undefined));
      dispatch(changeAnnotating(false));
      dispatch(changeEditing(null));
      return;
    }

    if (activeEntity) {
      dispatch(
        changeActiveEntity({ ...groupBlockEntityType, index: groupBlockIndex })
      );
      return;
    }

    if (isEditing) {
      dispatch(changeEditing(null));
      return;
    }

    dispatch(changeEditing(editingKey));
  }, [
    dispatch,
    disabled,
    groupBlockEntityType,
    groupBlockIndex,
    activeEntity,
    id,
    editingKey,
    isEditing,
  ]);

  const annotationForEntity = useMemo((): EntityAnnotation => {
    if (annotationsForEntity.length >= 1) {
      return {
        ...annotationsForEntity[0],
        index: annotationsForEntity[0].index!,
        pageTokenIndices: annotationsForEntity[0].pageTokenIndices!,
        value: annotationsForEntity[0].values.join(" ") || "",
        entity: annotationsForEntity[0].entity,
        modelScore: calcAverageScore(annotationsForEntity),
        isByUser: annotationsForEntity[0].isByUser || false,
        documentId: annotationsForEntity[0].documentId,
      };
    }

    return {
      page: 1,
      index: 1,
      pageTokenIndices: [],
      value: "",
      modelScore: -1,
      isByUser: false,
    };
  }, [annotationsForEntity]);

  return {
    handleEntityClick,
    removeAnnotation,
    annotationForEntity,
    annotationsForEntity,
    editingKey,
    isEditing,
    isAnnotating,
    isAnnotationLoading,
  };
};

export default useEntity;

import React from "react";
import TitleRowWithContent from "../../../../common/titleRowWithContent/TitleRowWithContent";
import Dragger from "antd/lib/upload/Dragger";
import { UploadProps, message } from "antd";
import "./CsvUpload.scss";
import { getVariableFromEnv } from "../../../../common/utilities/env";
import useAuthenticationCookie from "../../../../user/hooks/useAuthenticationCookie";
import { useAppSelector } from "../../../../app";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

const props: UploadProps = {
  showUploadList: false,
  multiple: false,
  accept: ".csv",
  name: "file",
  onChange(info) {
    const { status } = info.file;
    if (status === "done") {
      message.success("File uploaded successfully.");
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const CsvUpload = () => {
  const { cookie } = useAuthenticationCookie();

  const org = useAppSelector(
    (state) => state.userReducer.user?.organization?.name
  );

  if (org === "klassifai") {
    return (
      <div>
        <TitleRowWithContent title="Article - PO CSV" content={null}>
          <div>
            <Dragger
              {...props}
              action={`${API_BASE_URL}/customer/normalization/api/v1/upload/edmac/orders`}
              headers={{ Authorization: `Bearer ${cookie}` }}
            >
              <i
                className="ant-upload-drag-icon bi bi-upload"
                style={{ fontSize: "30px" }}
              />
              <p className="ant-upload-text">
                Click or drag CSV file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Upload a new version of the CSV containing the PO numbers and
                articles. Make sure the format of the CSV stays the same.
              </p>
            </Dragger>
          </div>
        </TitleRowWithContent>
        <br />
        <TitleRowWithContent title="Pallet conversion CSV" content={null}>
          <div>
            <Dragger
              {...props}
              action={`${API_BASE_URL}/customer/normalization/api/v1/upload/topsfoods/pallets`}
              headers={{ Authorization: `Bearer ${cookie}` }}
            >
              <i
                className="ant-upload-drag-icon bi bi-upload"
                style={{ fontSize: "30px" }}
              />
              <p className="ant-upload-text">
                Click or drag CSV file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Upload a new version of the CSV containing the pallet unit
                conversion. Make sure the format of the CSV stays the same.
              </p>
            </Dragger>
          </div>
        </TitleRowWithContent>
        <br />
        <TitleRowWithContent title="Route conversion CSV" content={null}>
          <div>
            <Dragger
              {...props}
              action={`${API_BASE_URL}/customer/normalization/api/v1/upload/topsfoods/routes`}
              headers={{ Authorization: `Bearer ${cookie}` }}
            >
              <i
                className="ant-upload-drag-icon bi bi-upload"
                style={{ fontSize: "30px" }}
              />
              <p className="ant-upload-text">
                Click or drag CSV file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Upload a new version of the CSV containing the pallet unit
                conversion. Make sure the format of the CSV stays the same.
              </p>
            </Dragger>
          </div>
        </TitleRowWithContent>
      </div>
    );
  }

  if (org === "edmac") {
    return (
      <div>
        <TitleRowWithContent title="Article - PO CSV" content={null}>
          <div>
            <Dragger
              {...props}
              action={`${API_BASE_URL}/customer/normalization/api/v1/upload/edmac/orders`}
              headers={{ Authorization: `Bearer ${cookie}` }}
            >
              <i
                className="ant-upload-drag-icon bi bi-upload"
                style={{ fontSize: "30px" }}
              />
              <p className="ant-upload-text">
                Click or drag CSV file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Upload a new version of the CSV containing the PO numbers and
                articles. Make sure the format of the CSV stays the same.
              </p>
            </Dragger>
          </div>
        </TitleRowWithContent>
      </div>
    );
  }

  if (org === "topsfoods") {
    return (
      <div>
        <TitleRowWithContent title="Pallet conversion CSV" content={null}>
          <div>
            <Dragger
              {...props}
              action={`${API_BASE_URL}/customer/normalization/api/v1/upload/topsfoods/pallets`}
              headers={{ Authorization: `Bearer ${cookie}` }}
            >
              <i
                className="ant-upload-drag-icon bi bi-upload"
                style={{ fontSize: "30px" }}
              />
              <p className="ant-upload-text">
                Click or drag CSV file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Upload a new version of the CSV containing the pallet unit
                conversion. Make sure the format of the CSV stays the same.
              </p>
            </Dragger>
          </div>
        </TitleRowWithContent>
        <br />
        <TitleRowWithContent title="Route conversion CSV" content={null}>
          <div>
            <Dragger
              {...props}
              action={`${API_BASE_URL}/customer/normalization/api/v1/upload/topsfoods/routes`}
              headers={{ Authorization: `Bearer ${cookie}` }}
            >
              <i
                className="ant-upload-drag-icon bi bi-upload"
                style={{ fontSize: "30px" }}
              />
              <p className="ant-upload-text">
                Click or drag CSV file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Upload a new version of the CSV containing the pallet unit
                conversion. Make sure the format of the CSV stays the same.
              </p>
            </Dragger>
          </div>
        </TitleRowWithContent>
      </div>
    );
  }

  return null;
};

export default CsvUpload;

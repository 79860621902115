import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app";
import { ITextVertex } from "../../annotator/interfaces/textLayer";
import selectAnnotationReducer from "./annotationReducerSelector";
import { getAvailableTokens } from "../utils/utils";

export interface ITextLayerForPage {
  text: string;
  tokens: Array<ITextVertex>;
}

type Props = {
  page: number;
  excludeTokensInUse: boolean;
};

const getPdf = (state: RootState) => state.documentReducer.pdf;
const getPage = (_: RootState, props: Props) => props.page;
const getExcludeTokensInUse = (_: RootState, props: Props) =>
  props.excludeTokensInUse;

const textLayerForPageSelector = createSelector(
  [getPdf, getPage, selectAnnotationReducer, getExcludeTokensInUse],
  (pdf, page, annotations, excludeTokensInUse): ITextLayerForPage | null => {
    if (!pdf) {
      return null;
    }

    const { text, pages } = pdf;

    const found = pages.find((p) => p.page === page);

    if (!found) {
      return null;
    }

    const { tokens: allTokens } = found;

    if (!allTokens.length) {
      return null;
    }

    let tokens = allTokens;

    if (excludeTokensInUse) {
      tokens = getAvailableTokens(allTokens, annotations, page);
    }

    return {
      //TODO: something weird is happening when only using page specific text
      text: text.slice(0, allTokens[allTokens.length - 1].textEndIndex),
      tokens,
    };
  }
);

export default textLayerForPageSelector;
